import React from 'react'
import PropTypes from 'prop-types'
import Vcard from '../atoms/Vcard'
import LogoUnit from '../molecules/LogoUnit'
import Networks from '../molecules/Networks'
import styles from './Footer.module.scss'
import { useMeta } from '../../hooks/use-meta'

const FooterMarkup = ({ meta, year }) => (
  <footer className={`h-card ${styles.footer}`}>
    <LogoUnit minimal />
    <Networks small />

    <p className={styles.actions}>
      <Vcard /> <a href="/resume.pdf" download>Download Resume</a> <a href="/coverletter.pdf" download>Download Cover Letter</a>
    </p>
    <p className={styles.copyright}>
      <small>
        &copy; {year}{' '}
        &mdash; All Rights Reserved
      </small>
    </p>
  </footer>
)

FooterMarkup.propTypes = {
  meta: PropTypes.object.isRequired,
  year: PropTypes.number.isRequired
}

export default function Footer() {
  const metaYaml = useMeta()
  const year = new Date().getFullYear()

  return <FooterMarkup year={year} meta={metaYaml} />
}
