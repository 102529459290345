// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-portfolio-templates-project-jsx": () => import("../src/portfolio/templates/Project.jsx" /* webpackChunkName: "component---src-portfolio-templates-project-jsx" */),
  "component---src-blog-templates-blog-post-tsx": () => import("../src/blog/templates/blog-post.tsx" /* webpackChunkName: "component---src-blog-templates-blog-post-tsx" */),
  "component---src-pages-404-jsx": () => import("../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-tsx": () => import("../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-jsx": () => import("../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

