import React from 'react'
import PropTypes, { element } from 'prop-types'
import PortfolioLayout from '../portfolio/components/Layout'

import { MDXProvider } from '@mdx-js/tag'
import { Code } from '../blog/components/code'
import { preToCodeBlock } from 'mdx-utils'

const wrapPageElementPortfolio = ({ element, props }) => (
    <PortfolioLayout {...props}>{element}</PortfolioLayout>
)

const components = {
    pre: preProps => {
        const props = preToCodeBlock(preProps)
        // if there's a codeString and some props, we passed the test
        if (props) {
            return <Code {...props} />
        } else {
            // it's possible to have a pre without a code in it
            return <pre {...preProps} />
        }
    }
}

const wrapPageElementBlog = ({ element }) => (
    <MDXProvider components={components}>{element}</MDXProvider>
)

const wrapPageElement = ({ element, props }) => {
    if (props.location.pathname.split('/')[1] === "blog") {
        return wrapPageElementBlog({
            element: element,
        }); 
    } else {
        return wrapPageElementPortfolio({
            element: element,
            props: props
        });  
    }  
};

wrapPageElement.propTypes = {
    element: PropTypes.any,
    props: PropTypes.any
}

export default wrapPageElement


